import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import UseCases from './components/UseCases';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import Features from './components/Features';
import SignupDialog from './components/SignupDialog';

export default function LandingPage() {
  const defaultTheme = createTheme({
    palette: {
      primary: { main: '#5B679A' },
      secondary: { main: '#8B939C' },
    }
  });
  const [signupOpen, setSignupOpen] = React.useState(false);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <SignupDialog open={signupOpen} setOpen={setSignupOpen} />
      <AppAppBar />
      <Hero setSignupOpen={setSignupOpen} />
      <Box sx={{ bgcolor: 'background.default' }}>
        <UseCases setSignupOpen={setSignupOpen} />
        <Features />
        <Divider />
        <FAQ />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
