import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Verified from '@mui/icons-material/Verified';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Settings from '@mui/icons-material/Settings';
import EventAvailable from '@mui/icons-material/EventAvailable';
import Language from '@mui/icons-material/Language';
import Business from '@mui/icons-material/Business';

const items = [
  {
    icon: <Verified />,
    title: 'Reliability',
    description:
      'Available 24/7 without waiting in queues, providing continuous service and immediate response times.',
  },
  {
    icon: <AttachMoney />,
    title: 'Cost-Efficiency',
    description:
      'No upfront costs; billing is usage-based. Enterprise support is available separately, providing a cost-effective solution tailored to your needs.',
  },
  {
    icon: <Settings />,
    title: 'Flexibility',
    description:
      'Integrated No-Code Automation Platform compatible with existing tools such as Zapier and n8n, offering seamless automation and customization without the need for programming skills.',
  },
  {
    icon: <EventAvailable />,
    title: 'Easy Appointment Scheduling',
    description:
      'Connects to your existing calendar, facilitating a smooth onboarding experience with minimal overhead.',
  },
  {
    icon: <Language />,
    title: 'Multilingual Support',
    description:
      'Understands over 90 languages, enabling effective engagement with a diverse international customer base.',
  },
  {
    icon: <Business />,
    title: 'Enterprise-Ready',
    description:
      '	Cloud-native SaaS, ensuring scalability to handle peak times without the need for managing infrastructure, making it an ideal solution for large enterprises.',
  },
];

export default function Features() {
  return (
    <Box
      id="features"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Features
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          Uncover the unique advantages of our product: seamless integration with your workflows, unmatched resilience, effortless usability, and groundbreaking technology.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
