import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';

const items = [
  {
    icon: <SupportAgentRoundedIcon />,
    title: 'Automated Customer Support',
    description:
      'Automate your business, no matter the size, to provide customer support 24/7 without writing a single line of code.',
    imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
  },
  {
    icon: <HelpOutlineRoundedIcon />, // questions hotline
    title: 'Internal Helpdesk',
    description:
      'Streamline your IT support with an intelligent, responsive helpdesk that resolves issues instantly and efficiently.',
    imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
  },
  {
    icon: <PublicRoundedIcon />,
    title: 'International Sales Support and Lead Qualification',
    description:
      'Our multilingual support system can help you expand on international markets.',
    imageLight: 'url("/static/images/templates/templates-images/devices-light.png")',
    imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
];

export default function UseCases({ setSignupOpen }) {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedUseCase = items[selectedItemIndex];

  return (
    <Container id="usecases" sx={{ py: { xs: 5, sm: 5 } }}>


      <div>
        <Typography component="h2" variant="h4" color="text.primary">
          Use Cases
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: { xs: 2, sm: 4 } }}
        >
          A portfolio of use cases that demonstrate the flexibility of the
          RingGPT platform.
        </Typography>
      </div>
      <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
        {items.map(({ title }, index) => (
          <Chip
            key={index}
            label={title}
            onClick={_ => handleItemClick(index)}
            sx={{
              borderColor: (theme) => {
                if (theme.palette.mode === 'light') {
                  return selectedItemIndex === index ? 'primary.light' : '';
                }
                return selectedItemIndex === index ? 'primary.light' : '';
              },
              background: (theme) => {
                if (theme.palette.mode === 'light') {
                  return selectedItemIndex === index ? 'none' : '';
                }
                return selectedItemIndex === index ? 'none' : '';
              },
              backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
              '& .MuiChip-label': {
                color: selectedItemIndex === index ? '#fff' : '',
              },
            }}
          />
        ))}
      </Grid>
      <Box
        component={Card}
        variant="outlined"
        sx={{
          display: { xs: 'auto', sm: 'none' },
          mt: 4,
        }}
      >
        <Box sx={{ px: 2, py: 2}}>
          <Typography color="text.primary" variant="body2" fontWeight="bold">
            {selectedUseCase.title}
          </Typography>
          <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
            {selectedUseCase.description}
          </Typography>
          <Link
            color="primary"
            variant="body2"
            fontWeight="bold"
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              '& > svg': { transition: '0.2s' },
              '&:hover > svg': { transform: 'translateX(2px)' },
            }}
            component="button"
            onClick={_ => setSignupOpen(true)}
          >
            <span>Learn more</span>
            <ChevronRightRoundedIcon
              fontSize="small"
              sx={{ mt: '1px', ml: '2px' }}
            />
          </Link>
        </Box>
      </Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        useFlexGap
        sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
      >
        {items.map(({ icon, title, description }, index) => (
          <Card
            key={index}
            variant="outlined"
            component={Button}
            onClick={_ => handleItemClick(index)}
            sx={{
              p: 3,
              height: 'fit-content',
              width: '100%',
              background: 'none',
              backgroundColor:
                selectedItemIndex === index ? 'action.selected' : undefined,
              borderColor: (theme) => {
                if (theme.palette.mode === 'light') {
                  return selectedItemIndex === index
                    ? 'primary.light'
                    : 'grey.200';
                }
                return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
              },
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                textAlign: 'left',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: { md: 'center' },
                gap: 2.5,
              }}
            >
              <Box
                sx={{
                  color: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.main'
                        : 'grey.300';
                    }
                    return selectedItemIndex === index
                      ? 'primary.main'
                      : 'grey.700';
                  },
                }}
              >
                {icon}
              </Box>
              <Box sx={{ textTransform: 'none' }}>
                <Typography
                  color="text.primary"
                  variant="body2"
                  fontWeight="bold"
                >
                  {title}
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                  sx={{ my: 0.5 }}
                >
                  {description}
                </Typography>
                <Link
                  color="primary"
                  variant="body2"
                  fontWeight="bold"
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    '& > svg': { transition: '0.2s' },
                    '&:hover > svg': { transform: 'translateX(2px)' },
                  }}
                  onClick={_ => setSignupOpen(true)}
                >
                  <span>Learn more</span>
                  <ChevronRightRoundedIcon
                    fontSize="small"
                    sx={{ mt: '1px', ml: '2px' }}
                  />
                </Link>
              </Box>
            </Box>
          </Card>
        ))}
      </Stack>
    </Container>
  );
}
