import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
} from '@mui/material';
import CountrySelect from './CountrySelect';


const callOptions = [
  { value: '0-100', label: '0-100' },
  { value: '100-1000', label: '100-1000' },
  { value: '1000-10000', label: '1000-10000' },
  { value: '10000-100000', label: '10000-100000' },
  { value: '100000+', label: '100000+' },
];

export default function SignupDialog({ open, setOpen }) {

  const [unavailableDialogOpen, setUnavailableDialogOpen] = React.useState(false);


  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [position, setPosition] = React.useState('');
  const [calls, setCalls] = React.useState('');
  const [country, setCountry] = React.useState('');

  function handleSubmit(e) {
    e.preventDefault();
    fetch('https://hooks.zapier.com/hooks/catch/19679764/2uj2enh/', {
      method: 'POST',
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email,
        company,
        position,
        calls,
        country,
      }),
    });

    if (typeof window.gtag_report_conversion === 'function') {
      window.gtag_report_conversion();
    } else {
      // Continue with other code or simply do nothing
      console.error("gtag_report_conversion does not exist");
    }

    setOpen(false);
    setUnavailableDialogOpen(true);
  }

  return <>
    <Dialog open={open} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle> Sign Up</DialogTitle>
        <DialogContent style={{paddingTop: '8px'}} >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <TextField
                label="First Name"
                name="first_name"
                fullWidth
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                variant="outlined"
                required
              />

            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                label="Last Name"
                name="last_name"
                fullWidth
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                variant="outlined"
                required
              />

            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                type='email'
                fullWidth
                value={email}
                onChange={e => setEmail(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <TextField
                label="Company"
                name="company"
                fullWidth
                value={company}
                onChange={e => setCompany(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                label="Position"
                name="position"
                fullWidth
                value={position}
                onChange={e => setPosition(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormControl fullWidth variant="outlined">
                <CountrySelect valueCallback={v => setCountry(v)} />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControl required fullWidth variant="outlined">
                <InputLabel>Estimated monthly calls</InputLabel>
                <Select
                  name="calls"
                  value={calls}
                  onChange={e => setCalls(e.target.value)}
                  label="Estimated monthly calls"
                >
                  {callOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={_ => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" id="signup_submit">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>

    <Dialog open={unavailableDialogOpen}>
      <DialogTitle>
        Notice of Service Availability
      </DialogTitle>
      <DialogContent>
        We regret to inform you that RingGPT is not yet available in your region. You will be notified promptly once access is granted.
      </DialogContent>
      <DialogActions>
        <Button onClick={_ => setUnavailableDialogOpen(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  </>
};